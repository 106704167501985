<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <div class="container" v-html="content.page.mainContent">

  </div>
</template>

<script>

export default{
  name:'SubPage',
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
div {
  margin: 0 auto;
  padding: 0;
}

img{
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1064px;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 2.75rem;
}

.container:deep(.responsive-youtube){
  overflow:hidden; padding-bottom:56.25%; position:relative; height:0;
}
.container:deep(.responsive-youtube iframe){
  left:0; top:0; height:100%; width:100%; position:absolute;
}

@media (max-width: 768px){
  .container {
    padding: 0 1rem;
  }
}
</style>